import React from 'react';

const Home = () => {
	return (
		<>
			{/* <!-- Headers-4 block --> */}
			{/* <!-- Heroheader14 block --> */}
			<section className="w3l-hero-headers-14">
				<div className="hh14-block">
					<section id="hh14-content">
						<div className="wrapper">
							<div>
								{/* <!-- main-slider --> */}
								<div className="main-slider">
									<div className="csslider infinity" id="slider1">
										<input type="radio" name="slides" checked="checked" id="slides_1" />
										<input type="radio" name="slides" id="slides_2" />
										<input type="radio" name="slides" id="slides_3" />
										<input type="radio" name="slides" id="slides_4" />

										<ul>
											<li>
												<div className="slider-info">
													<div className="d-grid hh14-text">
														<div className="hh14-info">
															{/* <h6>BUILDING UP VISION LEADING FUTURE</h6> */}
															<h3>Advanced Warehouse & Transportation </h3>
															<p>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
															</p>
															{/* <!-- <a href="single.html" className="actionbg btn">Start Exploring</a> --> */}
														</div>
													</div>
												</div>
											</li>

											<li>
												<div className="slider-info">
													<div className="d-grid hh14-text">
														<div className="hh14-info">
															<h6>BUILDING UP VISION LEADING FUTURE</h6>
															<h3>We are industry Factory Solutions </h3>
															<p>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
															</p>
															{/* <!-- <a href="single.html" className="actionbg btn">Start Exploring</a> --> */}
														</div>
													</div>
												</div>
											</li>
											<li>
												<div className="slider-info">
													<div className="d-grid hh14-text">
														<div className="hh14-info">
															<h6>BUILDING UP VISION LEADING FUTURE</h6>
															<h3>We Provide Best Industrial Services</h3>
															<p>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
															</p>
															{/* <!-- <a href="single.html" className="actionbg btn">Start Exploring</a> --> */}
														</div>
													</div>
												</div>
											</li>
											<li>
												<div className="slider-info">
													<div className="d-grid hh14-text">
														<div className="hh14-info">
															<h6>BUILDING UP VISION LEADING FUTURE</h6>
															<h3>Great Technology Best Services</h3>
															<p>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
															</p>
															{/* <!-- <a href="single.html" className="actionbg btn">Start Exploring</a> --> */}
														</div>
													</div>
												</div>
											</li>
										</ul>

										{/* <div className="navigation">
											<div>
												<label for="slides_1"></label>
												<label for="slides_2"></label>
												<label for="slides_3"></label>
												<label for="slides_4"></label>
											</div>
										</div> */}
									</div>
								</div>
								{/* <!-- /main-slider --> */}
							</div>
						</div>
					</section>
				</div>
			</section>
			{/* <!-- Heroheader14 block --> */}
			{/* <!-- specifications --> */}
			<section className="w3l-specifications-9">
				<div className="main-w3">
					<div className="wrapper">
						<div className="d-flex main-cont-wthree-fea">
							<div className="grids-speci">
								<div className="speci-inner">
									<div className="sp-lft">
										<span className="fa fa-handshake-o"></span>
									</div>
									<div className="sp-rgt">
										<h3 className="title-spe">280+</h3>
										<h4>
											<bold>Successfully</bold> completed projects
										</h4>
									</div>
								</div>
							</div>
							<div className="grids-speci midd-eff-spe">
								<div className="speci-inner">
									<div className="sp-lft">
										<span className="fa fa-users"></span>
									</div>
									<div className="sp-rgt">
										<h3 className="title-spe">400+</h3>
										<h4>
											<bold>Highly </bold> specialised employees
										</h4>
									</div>
								</div>
							</div>
							<div className="grids-speci midd-eff-las">
								<div className="speci-inner">
									<div className="sp-lft">
										<span className="fa fa-globe"></span>
									</div>
									<div className="sp-rgt">
										<h3 className="title-spe">15</h3>
										<h4>
											<bold>Countries </bold>
											around the world
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //specifications --> */}
			</section>
			{/* <!-- features --> */}
			{/* <!-- <section className="w3l-feature-9">
        <div className="main-w3">
            <div className="wrapper">
                <div className="main-cont-wthree-fea">
                    <div className="grids-feature">
                        <span className="fa fa-plug"></span>
                        <h4><a href="#feature" className="title-head">Automotive Manufacturing</a></h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>
                    <div className="grids-feature">
                        <span className="fa fa-cogs"></span>
                        <h4><a href="#feature" className="title-head">Mechanical Engineering</a></h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>
                    <div className="grids-feature">
                        <span className="fa fa-legal"></span>
                        <h4><a href="#feature" className="title-head">Industrial Construction</a></h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>
                    <div className="grids-feature">
                        <span className="fa fa-tint"></span>
                        <h4><a href="#feature" className="title-head">Oil & Gas
                                Energy</a></h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> --> */}
			{/* <!-- //features --> */}
			{/* <!-- covers --> */}
			<section className="w3l-covers-9-main">
				<div className="covers-9">
					<div className="wrapper">
						<div className="cover-top-center-9">
							<div className="w3ls_cover_txt-9">
								<h6 className="tag-cover-9">WE DO</h6>
								<h3 className="title-cover-9">Jet Engine Turbine and Aerodynamics</h3>
								<p className="para-cover-9">
									Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
									magna aliqua. Ut enim ad minim veniam, quis nostrud.
								</p>
								{/* <!-- <a href="services.html" className="actionbg btn">Read More</a> --> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- //covers --> */}
			{/* <!-- content-with-photo4 block --> */}
			{/* <!-- <section className="w3l-content-with-photo-4">
        <div id="content-with-photo4-block">
            <div className="wrapper">
                <div className="cwp4-two">
                    <div className="cwp4-text">
                        <h4>Maintenance and repairs of engines, prime movers and gas turbochargers.</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit
                            amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud.
                        </p>
                        <ul className="cont-4">
                            <li><span className="fa fa-arrow-circle-right" aria-hidden="true"></span> Dolor sit amet,
                                consectetu</li>
                            <li><span className="fa fa-arrow-circle-right" aria-hidden="true"></span> Sed do eiusmod tempor
                                incididunt ut labore </li>
                            <li><span className="fa fa-arrow-circle-right" aria-hidden="true"></span> Ut enim ad minim
                                veniam, quis nostrud.</li>
                            <li><span className="fa fa-arrow-circle-right" aria-hidden="true"></span> Sed do eiusmod tempor
                                incididunt ut labore </li>
                        </ul>
                    </div>
                    <div className="cwp4-image">
                        <img src="assets/images/e5.jpg" className="img-responsive" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section> --> */}
			{/* <!-- content-with-photo4 block --> */}
			<section className="w3l-features-6">
				{/* <!-- /features --> */}
				<div className="features">
					<div className="wrapper">
						<div className="fea-gd-vv">
							<div className="float-lt feature-gd">
								<div className="icon">
									{' '}
									<span className="fa fa-clipboard" aria-hidden="true"></span>
								</div>
								<div className="icon-info">
									<h3>Full Service</h3>
									<p>
										{' '}
										Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud.{' '}
									</p>
								</div>
							</div>
							<div className="float-mid feature-gd">
								<div className="icon">
									{' '}
									<span className="fa fa-wrench" aria-hidden="true"></span>
								</div>
								<div className="icon-info">
									<h3>Maintenance</h3>
									<p>
										{' '}
										Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud.{' '}
									</p>
								</div>
							</div>
							<div className="float-rt feature-gd">
								<div className="icon">
									{' '}
									<span className="fa fa-truck" aria-hidden="true"></span>
								</div>
								<div className="icon-info">
									<h3>Delivery</h3>
									<p>
										{' '}
										Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud.{' '}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //features --> */}
			</section>

			{/* <!--customers-7--> */}
			<section className="w3l-customers-7">
				<div className="customers_sur">
					<div className="wrapper">
						<h3>Testimonials</h3>
						<div className="customers-top_sur">
							<div className="customers-left_sur">
								<div className="customers_grid">
									<p className="sub-test">
										Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud
									</p>
									<div className="customers-bottom_sur">
										<div className="custo-img-res">
											<img src={require("../../assets/images/c1.jpg")} alt=" " className="img-responsive" />
										</div>
										<div className="custo_grid">
											<h5>Shane Watson</h5>
											<span>Co-founder</span>
										</div>
										<div className="clear"></div>
									</div>
								</div>
							</div>
							<div className="customers-middle_sur">
								<div className="customers_grid">
									<p className="sub-test">
										Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud
									</p>
									<div className="customers-bottom_sur">
										<div className="custo-img-res">
											<img src={require("../../assets/images/c2.jpg")} alt=" " className="img-responsive" />
										</div>
										<div className="custo_grid">
											<h5>Henry Nicholas</h5>
											<span>Client</span>
										</div>
										<div className="clear"></div>
									</div>
								</div>
							</div>
							<div className="customers-right_sur">
								<div className="customers_grid">
									<p className="sub-test">
										Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud
									</p>
									<div className="customers-bottom_sur">
										<div className="custo-img-res">
											<img src={require("../../assets/images/c3.jpg")} alt=" " className="img-responsive" />
										</div>
										<div className="custo_grid">
											<h5>Steve Waugh</h5>
											<span>Worker</span>
										</div>
										<div className="clear"></div>
									</div>
								</div>
							</div>
							<div className="clear"></div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
