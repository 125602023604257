import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const PageLayout = () => {
	const { pathname } = useLocation();
	// 	 const location = useLocation();

	// 	 React.useEffect(() => {
	//     console.log('Location changed', pathname);
	//   }, [location]);

	const isContactPage = pathname === '/contact';
	//  console.log("🚀 ~ file: page-layout.js:6 ~ PageLayout ~ pathname:", pathname)
	return (
		<>
			{/* <!-- Top Menu 1 --> */}
			<section className="w3l-top-menu-1 p-sticky-md">
				<div className="top-hd">
					<div className="wrapper">
						<header className="d-grid grid-columns-2">
							<div className="accounts">
								<li className="top_li">
									<span className="fa fa-mobile"></span>
									<a href="tel:9442294566">9442294566</a>
								</li>
								<li className="top_li1">
									<span className="fa fa-map-marker"></span> Coimbatore - Hosur{' '}
								</li>
								<li className="top_li2">
									<span className="fa fa-clock-o"></span> Mon - Sat 6:00 - 18:00 / Closed on Weekends
								</li>
							</div>
						</header>
					</div>
				</div>
			</section>
			{/* <!-- //Top Menu 1 -->
        <!-- Headers-4 block --> */}
			<section className="w3l-header-4">
				<header id="headers4-block" style={{ padding: 0 }}>
					<div className="wrapper">
						<div className="d-grid nav-mobile-block header-align">
							<div className="logo">
								{/* <!-- <a className="brand-logo" href="index.html"><span className="fa fa-industry"></span> M-Production</a> --> */}
								{/* <!-- if logo is image enable this    --> */}
								<Link className="brand-logo" to="/">
									<img src="./logo.png" style={{ height: 70 }} alt="Your logo" title="Your logo" />
								</Link>
							</div>
							<input type="checkbox" id="nav" />
							<label className="nav" for="nav"></label>
							<nav style={{ textAlign: 'right' }}>
								<label for="drop" className="toggle">
									Menu
								</label>
								<input type="checkbox" id="drop" />
								<ul className="menu">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/about">About</Link>
									</li>
									<li>
										<Link to="/services">Service</Link>
									</li>
									<li>
										<Link to="/events">Awards & Events</Link>
									</li>
									<li>
										<Link to="/gallery">Gallery</Link>
									</li>
									{/* <!-- Removed Nav  --> */}
									<li>
										<Link to="/contact">Contact</Link>
									</li>
								</ul>
							</nav>
							{/* <!-- <div className="button">
                            <a className="actionbg" title="login now" href="login.html">Login</a>
                        </div> --> */}
						</div>
					</div>
				</header>
			</section>
			<Outlet />
			{/* <!-- footer-29 block --> */}
			<section className="w3l-footer-29-main">
				<div className="footer-29">
					{!isContactPage && (
						<div className="fot-top">
							<div className="wrapper">
								<div className="d-grid bottom-source">
									<div className="grid-2-footer">
										<a className="foot-logo" href="index.html">
											<img src="./logo.png" style={{ height: 40 }} alt="Your logo" title="Your logo" />
										</a>
									</div>
									<div className="grid-1-footer">
										<Link to="/contact" className="actionbg1 btn">
											Contact Form <span className="fa fa-long-arrow-right" aria-hidden="true"></span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="wrapper">
						<div className="d-grid grid-col-4 footer-top-29">
							<div className="footer-list-29">
								<h6 className="footer-title-29">Company</h6>
								<ul>
									<li>
										<Link to="/about">About</Link>
									</li>
									<li>
										<Link to="/services">Service</Link>
									</li>
									<li>
										<Link to="/events">Awards & Events</Link>
									</li>
									<li>
										<Link to="/gallery">Gallery</Link>
									</li>
								</ul>
							</div>
							<div className="footer-list-29">
								<ul>
									<h6 className="footer-title-29">Solutions</h6>
									<li>
										<Link to="/services">Automotive Manfacturing</Link>
									</li>
									<li>
										<Link to="/services">Mechanical Engineering</Link>
									</li>
									<li>
										<Link to="/services">Industrial Construction</Link>
									</li>
									<li>
										<Link to="/services">Bridge Construction</Link>
									</li>
									<li>
										<Link to="/services">Oil & Gas Energy</Link>
									</li>
								</ul>
							</div>
							<div className="footer-list-29">
								<ul>
									<h6 className="footer-title-29">Industries</h6>
									<li>
										<Link to="/services">Electronic Materials</Link>
									</li>
									<li>
										<Link to="/services">Gift & Apparel</Link>
									</li>
									<li>
										<Link to="/services">Auto Parts</Link>
									</li>
									<li>
										<Link to="/services">Power Systems</Link>
									</li>
									<li>
										<Link to="/services">Building Management</Link>
									</li>
								</ul>
							</div>
							<div className="footer-list-29">
								<h6 className="footer-title-29">Contact Info</h6>
								<div className="cont-details-main">
									<div className="cont-top1">
										<div className="cont-left1">
											<span className="fa fa-mobile"></span>
										</div>
										<div className="cont-right1">
											<h6>
												<a href="tel:9442294566">9442294566</a>
											</h6>
											<p>Lorem ipsum dolor sit amet,</p>
										</div>
									</div>
									<div className="cont-top1">
										<div className="cont-left1">
											<span className="fa fa-clock-o"></span>
										</div>
										<div className="cont-right1">
											<h6>Working time</h6>
											<p>Mon - Fri: 9:00 - 19:00 / Closed on Weekends</p>
										</div>
									</div>
									<div className="cont-top1">
										<div className="cont-left1">
											<span className="fa fa-map-marker"></span>
										</div>
										<div className="cont-right1">
											<h6>Company Headquarters</h6>
											<p>Lorem ipsum street, Newyork City</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer_copy">
					<div className="wrapper">
						<div className="d-grid grid-col-2 midd-footer-29">
							<div className="main-social-footer-29">
								<a href="/">
									<span className="fa fa-facebook"></span>
								</a>
								<a href="/">
									<span className="fa fa-twitter"></span>
								</a>
								<a href="/">
									<span className="fa fa-instagram"></span>
								</a>
								<a href="/">
									<span className="fa fa-dribbble"></span>
								</a>
								<a href="/">
									<span className="fa fa-linkedin"></span>
								</a>
							</div>
							<ul className="list-btm-29">
								<li>
									<a href="#link">Privacy policy</a>
								</li>
								<li>
									<a href="#link">Term of service</a>
								</li>
								<li>
									<a href="#link">
										<select name="carlist" form="carform">
											<option value="volvo">Language</option>
											<option value="saab">Language 1</option>
											<option value="opel">Language 2</option>
											<option value="audi">Language 3</option>
										</select>
									</a>
								</li>
							</ul>
						</div>
						<div className="d-grid grid-col-2 bottom-copies">
							<p className="copy-footer-29">© 2023 TMT Transport. All rights reserved.</p>
						</div>
					</div>
				</div>
				{/* <!-- move top --> */}
				<button onclick="topFunction()" id="movetop" title="Go to top">
					<span className="fa fa-angle-up"></span>
				</button>
				{/* <!-- /move top --> */}
			</section>
		</>
	);
};

export default PageLayout;
